<div class="container">
    <div style="display: flex;justify-content: space-between;align-items: center; flex-flow: row-reverse;">

        <img (click)="cancelDialog()" height="10px" class="close_popup cursor-pointer"
            src="../../../../assets/svg/close.svg" />
    </div>

    <div class="row d-flex info-dialog justify-content-center flex-column" mat-dialog-content>
        <img src="../../../../assets/svg/upgrade/payment-success.svg" class="mb-1" style="height: 110px;">
        <!-- <h3 class="font-weight-bold text-center font-18 mb-1 hClr">Payment Successful</h3>
        <div class="text-center form-sub-heading">Congratulations. Your plan has been upgraded.
        </div> -->
        <div class="text-center form-sub-heading">Please contact your Institute Coordinator to upgrade your plan
        </div>
    </div>

    <div class="row mat-dialog-actions" mat-dialog-actions>

        <div class="col-sm-12 text-center pt-1 pb-2">
            <button mat-flat-button class="action-btn dialog-btn cancel-button shadowClass action-btn-text w-100"
                (click)="closeDialog()" translate="no" style="padding: 9px !important;">Go to Home</button>

        </div>
    </div>
</div>